import { useEffect } from "react";
import { patchFormData, postData, postFormData } from "../../../../../services";
import { useLocation, useParams } from "react-router-dom";

const CancelInvite = () => {
  const location = useLocation();

  const getQueryParams = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const inviteId = getQueryParams("invite_id");

  const handleConfirmStatus = async () => {
    try {
      const res = await patchFormData({
        endpoint: "Invite/editStatus",
        params: {
          status: "Cancelled",
          id: inviteId,
        },
        data: {
          qr_image: null,
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleConfirmStatus();
  }, []);

  return (
    <div
      className=" d-flex  justify-content-center"
      style={{
        height: "100vh",
        width: "100vw",
        fontSize: "24px",
        fontWeight: "500",
        alignItems: "center",
      }}
    >
      Your Invitation has been Cancelled
    </div>
  );
};
export default CancelInvite;
