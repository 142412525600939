import React, { useCallback, useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { v4 as uuidv4 } from "uuid";
import PageHeader from "../../../../../atoms/PageHeader";
import Search from "../../../../../molecules/search";
import CustomButton from "../../../../../atoms/CustomButton";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import Pagination from "../../../../../molecules/Pagination";
import { setLoading } from "../../../../../../store/slices/loaderSlice";
import Modal from "../../../../../organisms/modals/Modal";
import DeleteModal from "../../../../../organisms/modals/DeleteModal";
import Form from "./Form";
import { getData } from "../../../../../../services";
import { render } from "@testing-library/react";
import Edit from "../../../../../icons/Edit";
import Delete from "../../../../../icons/Delete";
import { useDelete } from "../../../../../../hooks/useDelete";
import NoContentIcon from "../../../../../icons/NocontentIcon";
import NoContentCard from "../../../../../atoms/NoContentCard";
import HorizontalMenu from "../../../../../icons/HorizontalMenu";
import CustomMenu from "../../../../../atoms/CustomMenu";

const Departments = () => {
  const [cookies] = useCookies(["b_t", "bid"]);
  const [tableData, setTableData] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });

  const getUserData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getData({
        endpoint: "BusinessConfiguration/getAllDepartment",
        params: {
          search_query: searchedValue,
          page: currentPage,
          business_id: cookies.bid,
          page_limit: itemsPerPage,
        },
        token: cookies.b_t,
      });

      if (res) {
        const dataWithSNo = res.data.map((item, index) => ({
          ...item,
          sno: (currentPage - 1) * itemsPerPage + index + 1,
        }));
        setTableData({ ...res, data: dataWithSNo });
      }
    } catch (error) {
      console.error(error); // Use console.error for errors
    }
  }, [searchedValue, cookies.b_t, currentPage, itemsPerPage]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  const columns = [
    {
      field: "sno",
      headerName: "S.No.",
      flex: 1,
      sortable: false, // Serial numbers are typically not sortable
      editable: false,
    },
    {
      field: "name",
      headerName: "Department",
      flex: 1,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="table-icons-container">
            <div onClick={(event) => handleStatusMenu(event, params.row)}>
              <HorizontalMenu />
            </div>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={menu}
            />
          </div>
        );
      },
    },
  ];
  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const menu = [
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        setAnchorEl(null);
        handleEdit(selectedRow);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setAnchorEl(null);
        handleDeleteModal(selectedRow);
      },
    },
  ];

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        {!!searchedValue ? (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        ) : (
          <NoContentCard
            title="No Department Added Yet!"
            subtitle="Click on Add Department button to add a department"
            tag="Add Department"
            handleClick={() => openModal("Add Department")}
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        )}
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  // add , edit , delete
  const openModal = (title, data) => {
    const modalId = uuidv4();
    setModalState((prevState) => ({
      ...prevState,
      modalId,
      isModalOpen: true,
      title,
      modalData: data,
    }));
  };

  const closeModal = () => {
    setModalState((prevState) => ({ ...prevState, isModalOpen: false }));
  };

  const handleEdit = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      modalId: data.id,
      modalData: data,
      isModalOpen: true,
      title: "Edit Department",
    }));
  };

  const deleteUser = useDelete();

  const handleDeleteModal = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: data.id,
      name: data.name,
    }));
  };

  const handleDeletion = async () => {
    try {
      await deleteUser("BusinessConfiguration/deleteDepartment", "Department", {
        id: modalState.modalId,
      });
      getUserData();
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="d-flex space-between">
        <PageHeader title="Departments" type="small" removeHeader={true} />
        <div className="d-flex gap-3 header-tab-between">
          <Search
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />
          <CustomButton
            type="btn-primary"
            text="Add Department"
            handleClick={() => openModal("Add Department")}
          />
        </div>
      </div>

      <CustomDatagrid
        getRowId={(row) => row.id}
        rows={tableData?.data || []}
        columns={columns}
        // tHeight="50vh"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {tableData?.total_record > 25 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={tableData?.total_record}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}

      {modalState?.isModalOpen && (
        <Modal
          id={modalState.modalId}
          isActive={modalState.isModalOpen}
          onClose={closeModal}
          title={modalState.title}
          extrastyle={{
            overflow: "visible",
            cssText: "overflow: visible !important",
          }}
          width="500px"
        >
          <Form
            onClose={closeModal}
            id={modalState.modalId}
            initialValues={
              modalState.title === "Edit Department"
                ? modalState.modalData
                : null
            }
            refetchData={getUserData}
          />
        </Modal>
      )}

      {modalState.isDeleteModalOpen && (
        <DeleteModal
          name={modalState.name}
          id={modalState.modalId}
          isActive={modalState.isDeleteModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isDeleteModalOpen: false,
            }))
          }
          title="Department"
          onClick={handleDeletion}
        />
      )}
    </>
  );
};

export default Departments;
