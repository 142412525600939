import { useEffect, useRef, useState } from "react";
import FilterIcon from "../../icons/FilterIcon";
import CustomButton from "../../atoms/CustomButton";
import { DateSelector } from "../MonthYearSelector";
import Close from "../../icons/Close";
import "./_filter.scss";
import CheckboxWrapper from "../../atoms/CheckBoxWrapper/Checkbox";

const FilterComponent = ({
  onFilterChange,
  filterData: propsFilterData,
  isDateRange = false,
  headingMobile,
  headingText = "Filters",
  immediateChange,
  onEmitChange,
  selectedData,
  activeKey,
  filters,
  setFilters,
  className,
}) => {
  const containerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");

  const filterData = propsFilterData || {};

  const updatedFilterData = isDateRange
    ? { ...filterData, "Date Range": ["startDate", "endDate"] }
    : filterData;

  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedKey, setSelectedKey] = useState();
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});

  // useEffect(() => {
  //   console.log("Enteres boss :", activeKey);

  //   setSelectedKey(activeKey);
  // }, [activeKey]);

  useEffect(() => {
    const keys = Object.keys(filterData);
    if (activeKey) {
      setSelectedKey(activeKey);
    } else {
      setSelectedKey(keys[0]);
    }

    if (immediateChange) {
      if (activeKey !== "Employees") {
        if (selectedOptionsMap && selectedOptionsMap?.Employees?.length > 0) {
          setSelectedOptionsMap({
            ...selectedOptionsMap,
            Employees: [],
          });
        }
      }
    }
  }, [filterData]);

  useEffect(() => {
    if (selectedData && selectedData.length > 0) {
      const keys = Object.keys(selectedData[0]);
      setSelectedKey(keys[0]);

      setSelectedOptionsMap({
        ...selectedOptionsMap,
        Status: [selectedData?.[0]?.Status?.value],
      });
      onFilterChange({ Status: [selectedData?.[0]?.Status?.value] });
    }
  }, [selectedData]);

  useEffect(() => {
    if (immediateChange) {
      onEmitChange(selectedOptionsMap, selectedKey);
    }
  }, [selectedOptionsMap]);

  const toggleFilterBox = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyClick = (key) => {
    setSelectedKey(key);
  };

  const handleOptionChange = (option) => {
    setSelectedOptionsMap((prevOptionsMap) => {
      const updatedOptionsMap = { ...prevOptionsMap };

      if (!updatedOptionsMap[selectedKey]) {
        updatedOptionsMap[selectedKey] = [];
      }

      if (updatedOptionsMap[selectedKey].includes(option)) {
        updatedOptionsMap[selectedKey] = updatedOptionsMap[selectedKey].filter(
          (prevOption) => prevOption !== option
        );
      } else {
        updatedOptionsMap[selectedKey] = [
          ...updatedOptionsMap[selectedKey],
          option,
        ];
      }

      return updatedOptionsMap;
    });
  };

  useEffect(() => {
    if (!isDateRange) {
      const handleClickOutside = (event) => {
        if (
          isOpen &&
          containerRef.current &&
          !containerRef.current.contains(event.target) &&
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("click", handleClickOutside);

      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [isOpen]);

  const handleApply = () => {
    const filterValues = {
      ...selectedOptionsMap,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    };

    onFilterChange(filterValues);
    setIsOpen(false);
  };

  const handleReset = () => {
    setSelectedOptionsMap({});
    setDateRange({ startDate: null, endDate: null });
    setIsOpen(false);
    onFilterChange({});
  };

  const handleChecked = (value) => {
    const val =
      selectedOptionsMap[selectedKey] &&
      selectedOptionsMap[selectedKey].includes(value);

    return val || false;
  };

  const filteredOptions = updatedFilterData[selectedKey]?.filter((option) =>
    option.label?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div
      className={
        headingMobile
          ? "custom-filter-container filter-mobile-container"
          : "custom-filter-container"
      }
      ref={containerRef}
    >
      <CustomButton
        handleClick={toggleFilterBox}
        icon={<FilterIcon />}
        text={headingMobile ? "" : headingText}
        classes={`${headingMobile ? "filter-mobile-container-btn" : ""}
        ${
          (Array.isArray(selectedOptionsMap) &&
            selectedOptionsMap.length > 0) ||
          (selectedOptionsMap && Object.keys(selectedOptionsMap)?.length > 0)
            ? "active-filter"
            : ""
        }
        `}
      />

      {isOpen && (
        <div className={`filter-box ${className}`} ref={dropdownRef}>
          <div className="filter-header">
            <p className="header">Filters</p>

            <span
              className="header"
              style={{ cursor: "pointer" }}
              onClick={toggleFilterBox}
            >
              <i className="d-flex">
                <Close />
              </i>
            </span>
          </div>
          <div className="filter-data-box">
            <div className="filter-key-box">
              {Object.keys(updatedFilterData).map((key) => (
                <div
                  key={key}
                  onClick={() => handleKeyClick(key)}
                  className={`filter-key ${
                    selectedKey === key ? "active-key" : ""
                  }`}
                >
                  {key}
                  {selectedOptionsMap[key]?.length > 0 && (
                    <div className="dot-primary"> </div>
                  )}
                </div>
              ))}
            </div>

            <div className="filters-column">
              <>
                <input
                  key={selectedKey}
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  style={{
                    height: "35px",
                    marginBottom: "15px",
                    width: "100%",
                  }}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {filteredOptions?.map((option) => (
                  <div key={option.value} className="mb-1">
                    <label
                      className="checkbox_container mb-4"
                      htmlFor={option.value}
                      style={{ width: "100%" }}
                    >
                      <label
                        htmlFor=""
                        className="label-checkobox-text"
                        title={option?.label}
                      >
                        {option.label}
                      </label>

                      <CheckboxWrapper
                        checked={handleChecked(option.value)}
                        onChange={() => handleOptionChange(option.value)}
                      />
                    </label>
                  </div>
                ))}
              </>
            </div>
          </div>
          <hr className="my-0" />
          <div className="d-flex justify-content-end gap-3 p-3">
            <button className="btn btn-outline-dark mt-0" onClick={handleReset}>
              Reset
            </button>
            <button className="btn btn-primary mt-0" onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterComponent;
