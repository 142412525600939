// import React, { useState, useEffect, useRef } from "react";
// import Modal from "../../../../organisms/modals/Modal";
// import "./styles.scss";
// import { getData } from "../../../../../services";
// import { useCookies } from "react-cookie";
// import toast from "react-hot-toast";
// import { postData } from "../../../../../services";
// import { confimationStyles } from "../../../../../assets/styles/toast";

// const ResendEmail = ({ isActive, setIsActive }) => {
//   const [searchQuery, setSearchQuery] = useState("");
//   const [selectedOptions, setSelectedOptions] = useState([]);
//   const [filteredData, setFilteredData] = useState([]);
//   const [selectAll, setSelectAll] = useState(false);
//   const [peopleData, setPeopleData] = useState([]);
//   const [page, setPage] = useState(1);
//   const [loading, setLoading] = useState(false);
//   const [hasMore, setHasMore] = useState(true);
//   const [cookies] = useCookies(["b_t", "bid"]);
//   const scrollContainerRef = useRef(null);

//   const getPeopleData = async (newPage) => {
//     setLoading(true);
//     const res = await getData({
//       endpoint: "Employe/getInactiveEmployee",
//       params: {
//         business_id: cookies.bid,
//         page: newPage,
//         page_limit: 20,
//       },
//       token: cookies.b_t,
//     });

//     if (res.data && res.data.length > 0) {
//       setPeopleData((prevPeople) => [...prevPeople, ...res.data]);
//       setHasMore(true);
//     } else {
//       setHasMore(false);
//     }
//     setLoading(false);
//   };

//   useEffect(() => {
//     if (isActive) {
//       setPage(1);
//       setPeopleData([]);
//       getPeopleData(1);
//     }
//   }, [isActive]);

//   useEffect(() => {
//     const filteredOptions = peopleData
//       .filter(
//         (option) =>
//           option?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
//           option?.email?.toLowerCase().includes(searchQuery.toLowerCase())
//       )
//       .sort((a, b) => {
//         const aSelected = selectedOptions.includes(a.id);
//         const bSelected = selectedOptions.includes(b.id);
//         if (aSelected && !bSelected) return -1;
//         if (!aSelected && bSelected) return 1;
//         return 0;
//       });
//     setFilteredData(filteredOptions);
//   }, [searchQuery, selectedOptions, peopleData]);

//   const handleOptionChange = (option) => {
//     const isSelected = selectedOptions.includes(option.id);
//     const updatedOptions = isSelected
//       ? selectedOptions.filter((id) => id !== option.id)
//       : [...selectedOptions, option.id];

//     setSelectedOptions(updatedOptions);
//   };

//   const handleSelectAllChange = () => {
//     if (selectAll) {
//       setSelectedOptions([]);
//     } else {
//       setSelectedOptions(filteredData.map((option) => option.id));
//     }
//     setSelectAll(!selectAll);
//   };

//   const handleScroll = () => {
//     if (scrollContainerRef.current) {
//       const { scrollTop, scrollHeight, clientHeight } =
//         scrollContainerRef.current;
//       if (scrollTop + clientHeight >= scrollHeight - 5 && !loading && hasMore) {
//         setPage((prevPage) => prevPage + 1);
//       }
//     }
//   };

//   useEffect(() => {
//     if (page > 1) {
//       getPeopleData(page);
//     }
//   }, [page]);

//   const handleLinkSend = async () => {
//     const res = await postData({
//       endpoint: "Employe/sendCreatePasswordLink",
//       token: cookies.t,
//       data: selectedOptions,
//     });

//     if (res) {
//       setSelectedOptions([]);
//       setIsActive(false);
//       toast(`Password creation link Sent successfully.`, {
//         style: confimationStyles,
//         duration: 1000,
//       });
//     }
//   };

//   return (
//     <Modal
//       isActive={isActive}
//       setIsActive={setIsActive}
//       width="650px"
//       onClose={() => setIsActive(false)}
//       title="Send Password Link"
//       bodyStyles={{ padding: 0 }}
//     >
//       <div className="py-3 px-0">
//         <div
//           className="inactive-data-container"
//           ref={scrollContainerRef}
//           onScroll={handleScroll}
//         >
//           <input
//             type="text"
//             placeholder="Search"
//             className="resend-dataInput"
//             value={searchQuery}
//             onChange={(e) => setSearchQuery(e.target.value)}
//             style={{ width: "100%" }}
//           />
//           {/* {filteredData?.length > 0 && (
//             <div className="mb-4">
//               <label
//                 className="check-container"
//                 htmlFor="selectAll"
//                 style={{ fontSize: 16 }}
//               >
//                 Select All
//                 <input
//                   type="checkbox"
//                   id="selectAll"
//                   checked={selectAll}
//                   onChange={handleSelectAllChange}
//                 />
//                 <span className="checkmark" style={{ top: 3 }}></span>
//               </label>
//             </div>
//           )} */}

//           {console.log("filter", filteredData.length)}
//           {filteredData?.length > 0 ? (
//             <div className="reset-data-container mt-2">
//               {filteredData.map((option) => (
//                 <div key={option.id} className="mb-1">
//                   <label
//                     className="check-container"
//                     htmlFor={option.name}
//                     style={{ fontSize: 16 }}
//                   >
//                     {option.name} <br />
//                     {option.email}
//                     <input
//                       type="checkbox"
//                       id={option.name}
//                       checked={selectedOptions.includes(option.id)}
//                       onChange={() => handleOptionChange(option)}
//                     />
//                     <span className="checkmark" style={{ top: 3 }}></span>
//                   </label>
//                 </div>
//               ))}
//             </div>
//           ) : (
//             <div className="d-flex align-items-center justify-content-center p-4 mb-3">
//               <span className="h4-j ">No Employees Found</span>
//             </div>
//           )}
//           {/* {loading && <div>Loading...</div>} */}
//         </div>
//         <div className="d-flex justify-content-end resend-footer">
//           <button className="btn btn-primary" onClick={() => handleLinkSend()} disabled={selectedOptions.length === 0}>
//             Resend Password Link
//           </button>
//         </div>
//       </div>
//     </Modal>
//   );
// };

// export default ResendEmail;

import React, { useState, useEffect, useRef } from "react";
import Modal from "../../../../organisms/modals/Modal";
import "./styles.scss";
import { getData } from "../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { postData } from "../../../../../services";
import { confimationStyles } from "../../../../../assets/styles/toast";

const ResendEmail = ({ isActive, setIsActive }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [peopleData, setPeopleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [sendToAll, setSendToAll] = useState(false);
  const [selectManually, setSelectManually] = useState(false);
  const [page, setPage] = useState(1);
  const [cookies] = useCookies(["b_t", "bid"]);
  const scrollContainerRef = useRef(null);

  console.log("selectManually", selectManually);
  // Fetch employees
  const getPeopleData = async (newPage) => {
    setLoading(true);
    const res = await getData({
      endpoint: "Employe/getInactiveEmployee",
      params: {
        business_id: cookies.bid,
        page: newPage,
        page_limit: 20,
      },
      token: cookies.b_t,
    });

    if (res.data && res.data.length > 0) {
      setPeopleData((prevPeople) => [...prevPeople, ...res.data]);
      setHasMore(true);
    } else {
      setHasMore(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isActive) {
      setPage(1);
      setPeopleData([]);
      if (selectManually && peopleData.length === 0) {
        getPeopleData(1);
      }
    }
  }, [isActive, selectManually]);



  // Filter employees based on search
  useEffect(() => {
    const filteredOptions = peopleData
      .filter(
        (option) =>
          option?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          option?.email?.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => {
        const aSelected = selectedOptions.includes(a.id);
        const bSelected = selectedOptions.includes(b.id);
        return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
      });

    setFilteredData(filteredOptions);
  }, [searchQuery, selectedOptions, peopleData]);

  // Handle manual selection
  const handleOptionChange = (option) => {
    const isSelected = selectedOptions.includes(option.id);
    const updatedOptions = isSelected
      ? selectedOptions.filter((id) => id !== option.id)
      : [...selectedOptions, option.id];

    setSelectedOptions(updatedOptions);
  };

  const handleScroll = () => {
    if(scrollContainerRef.current){
      const {scrollTop, scrollHeight, clientHeight} = scrollContainerRef.current;
      if(scrollTop + clientHeight >= scrollHeight - 5 && !loading && hasMore) {
        setPage((prevPage) =>  prevPage + 1)
      }
    }
  }

    useEffect(() => {
    if (page > 1) {
      getPeopleData(page);
    }
  }, [page]);

  // Handle "Send to All" checkbox
  const handleSendToAllChange = () => {
    setSendToAll((prev) => !prev);
    setSelectManually(false);
    setSelectedOptions([]); // Reset selection when switching modes
  };

  // Handle "Select Manually" checkbox
  const handleSelectManuallyChange = () => {
    setSelectManually((prev) => !prev);
    setSendToAll(false);
    setSelectedOptions([]); // Reset selection when switching modes
  };

  // Handle sending links
  const handleLinkSend = async () => {
    const res = await postData({
      endpoint: "Employe/sendCreatePasswordLink",
      token: cookies.t,
      data: selectedOptions,
    });

    if (res) {
      setSelectedOptions([]);
      setIsActive(false);
      toast(`Password creation link Sent successfully.`, {
        style: confimationStyles,
        duration: 1000,
      });
    }
  };

  return (
    <Modal
      isActive={isActive}
      setIsActive={setIsActive}
      width="680px"
      onClose={() => setIsActive(false)}
      title="Send Password Link"
      bodyStyles={{ padding: 0 }}
    >
      <div className="py-3 px-0">
        <div className="px-4">
          {/* Checkboxes for selection mode */}

          <div className="mb-1">
            <label className="check-container">
              Send to all employees with Pending status
              <input
                type="checkbox"
                id="sendToAll"
                checked={sendToAll}
                onChange={handleSendToAllChange}
              />
              <span className="checkmark" style={{ top: "1px" }}></span>
            </label>
          </div>

          <div className="mb-1">
            <label className="check-container">
              Select Manually
              <input
                type="checkbox"
                id="selectManually"
                checked={selectManually}
                onChange={handleSelectManuallyChange}
              />
              <span className="checkmark" style={{ top: "1px" }}></span>
            </label>
          </div>
        </div>

        {/* Show search bar and list only when "Select Manually" is checked */}
        {selectManually && (
          <>
            <div className="px-4 mt-3">
              <input
                type="text"
                placeholder="Search"
                className="resend-dataInput"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>

            <div
              className="inactive-data-container"
              ref={scrollContainerRef}
              onScroll={handleScroll}
            >
              {filteredData.length > 0 ? (
                <div className="reset-data-container mt-2">
                  {filteredData.map((option) => (
                    <div key={option.id} className="mb-1">
                      <label className="check-container">
                        {option.name} <br />
                        {option.email}
                        <input
                          type="checkbox"
                          checked={selectedOptions.includes(option.id)}
                          onChange={() => handleOptionChange(option)}
                        />
                        <span
                          className="checkmark"
                          style={{ top: "1px" }}
                        ></span>
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center p-4 mb-3">
                  <span className="h4-j">No Employees Found</span>
                </div>
              )}
            </div>
          </>
        )}

        {/* Footer buttons */}
        <div className="d-flex justify-content-end resend-footer">
          <button
            className="btn btn-primary"
            onClick={handleLinkSend}
            disabled={selectedOptions.length === 0 && !sendToAll}
          >
            Send Password Link
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ResendEmail;
