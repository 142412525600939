export const forceTerminl = [
  { label: "Midnight", value: "Midnight" },
  { label: "After 24hrs", value: "After 24hrs" },
];

export const defaultInviteStatus = [
  { label: "Confirmed", value: "Confirmed" },
  { label: "Pending", value: "Pending" },
];

export const statusData = [
  { label: "Active", value: 'Active' },
  { label: "Inactive", value: 'Inactive' },
  { label: "Pending", value: 'Pending' },
];

export const boolStatusData = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

export const majorStatus = [
  { label: "Active", value: 'Active' },
  { label: "Inactive", value: 'Inactive' },
]

export const userRoles = [
  { label: "Admin", value: "Admin" },
  { label: "Manager", value: "Manager" },
  { label: "Viewer", value: "Viewer" },
];

export const statusInvites = [
  { label: "Ongoing", value: "Ongoing" },
  { label: "Completed", value: "Completed" },
  {label : 'Unheld' , value : 'Unheld'},
  {label : 'Pending' , value : 'Pending'},
  {label : 'Cancelled' , value : 'Cancelled'},
];

export const activityStatusInvites = [
  { label: "Ongoing", value: "Ongoing" },
  { label: "Completed", value: "Completed" },
]

export const businessTypes = [
  { value: "Private Limited", label: "Private Limited" },
  { value: "Public Limited", label: "Public Limited" },
  { value: "Sole Proprietorship", label: "Sole Proprietorship" },
  { value: "Individual", label: "Individual" },
  { value: "Partnership", label: "Partnership" },
  { value: "Limited Liability Company", label: "Limited Liability Company" },
  {
    value: "Limited Liability Partnership",
    label: "Limited Liability Partnership",
  },
  { value: "Corporation", label: "Corporation" },
  {
    value: "Non-Government Organization",
    label: "Non-Government Organization",
  },
];

export const modesData = [
  { label: "Employee Mode", value: 1 },
  { label: "Admin Mode", value: 2 },

];
