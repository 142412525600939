import addMonths from "date-fns/addMonths";
import endOfMonth from "date-fns/endOfMonth";
import startOfMonth from "date-fns/startOfMonth";
import subDays from "date-fns/subDays";
import differenceInDays from "date-fns/differenceInDays";
import moment from "moment";
import { DateRangePicker, Button } from "rsuite";
import style from "./index.module.scss";
import "./dateRangePicker.scss";
import "rsuite/DateRangePicker/styles/index.css";
import { addHours, startOfDay } from "rsuite/esm/internals/utils/date";
import { useState } from "react";

export const adjustToUTC = (date) => {
  const utcDate = new Date(date?.getTime() - date?.getTimezoneOffset() * 60000);
  return utcDate.toISOString();
};

const DateRangePickerShortCuts = ({
  setDate,
  className,
  type,
  placement = "bottomEnd",
}) => {
  const [selectedRange, setSelectedRange] = useState(null);
  const [disableOk, setDisableOk] = useState(false);

  const todays = new Date();
  const defaultToday = new Date();
  const thirtyDaysAgo = moment(todays).subtract(30, "days").startOf("day");

  const predefinedRanges = [
    {
      label: "Last 7 days",
      value: [subDays(new Date(), 6), new Date()],
      placement: "left",
    },
    {
      label: "Last 30 days",
      value: [subDays(new Date(), 29), new Date()],
      placement: "left",
    },
    {
      label: "Last 60 days",
      value: [subDays(new Date(), 59), new Date()],
      placement: "left",
    },
    {
      label: "Activities can only be viewed for a maximum span of 2 months.",
    },
  ];

  const defaultVal = [
    addHours(startOfDay(startOfMonth(defaultToday)), 12),
    addHours(startOfDay(endOfMonth(defaultToday)), 12),
  ];

  const handleDateChange = (data) => {
    if (data?.length) {
      const diffDays = differenceInDays(data[1], data[0]);

      if (diffDays > 60) {
        setDisableOk(true);
      } else {
        setDisableOk(false);
      }

      setDate(() => ({
        start_date: adjustToUTC(data?.[0]),
        end_date: adjustToUTC(data?.[1]),
      }));

      setSelectedRange(data);
    } else {
      setSelectedRange(null);
      setDisableOk(false);
      setDate(() => ({
        start_date: thirtyDaysAgo.toISOString(),
        end_date: todays.toISOString(),
      }));
    }
  };

  return (
    <div
      className={`${type === "header-filter" ? "header-daterange" : ""} ${
        style.dateRangeWrapper
      }`}
    >
      <div className={style.dateRangeShortCut}>
        <DateRangePicker
          className={`${style.dateRange} ${className}`}
          ranges={predefinedRanges}
          placeholder="Date Range Select"
          style={{ width: 245 }}
          character={" - "}
          editable
          format="dd/MM/yyyy"
          defaultCalendarValue={defaultVal}
          defaultValue={defaultVal}
          //   value={selectedRange}
          onShortcutClick={(shortcut) => {
            setDisableOk(false);
            setSelectedRange(shortcut.value);
            setDate({
              start_date: adjustToUTC(shortcut?.value?.[0]),
              end_date: adjustToUTC(shortcut?.value?.[1]),
            });
          }}
          onChange={handleDateChange}
          appearance={"default"}
          placement={placement}
          disabledDate={(date, selected) => {
            if (!selected?.[0]) return false;
            const diffDays = differenceInDays(date, selected[0]);
            return diffDays > 60;
          }}
        />
      </div>
    </div>
  );
};

export default DateRangePickerShortCuts;
