import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";

import PageHeader from "../../../../atoms/PageHeader";
import PageCard from "../../../../atoms/PageCard";
import BackBtn from "../../../../atoms/BackBtn";
import SelectDropdown from "../../../../molecules/Dropdown";
import CustomButton from "../../../../atoms/CustomButton";

import Error from "../../../../atoms/Error";
import InputLayout from "../../../../atoms/InputLayout";
import { countryCodeData } from "../../../../../helpers/countryCodes";
import Modal from "../../../../organisms/modals/Modal";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDepartmentList,
  getDesignationList,
  getDeviceList,
  getEmployeeVenueList,
  getVenueList,
} from "../../../../../services/basicGets";
import { useCookies } from "react-cookie";
import VenueDataSElection from "./VenueDataSElection";
import { getData, patchData, postData } from "../../../../../services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import { majorStatus } from "../../../../../helpers/staticData";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { vehicleNumberRegex } from "../../../../../helpers/regex";
import { MODE_BUSINESS } from "../../../../../helpers/constants";
import CountryCodeDropdown from "../../../../molecules/CountryCodeDropdown";

const Add = ({ type = "add", mode }) => {
  const [cookies] = useCookies(["b_t", "bid", "buid"]);
  const navigate = useNavigate();
  const [venueData, setVenueData] = useState([]);
  const [updatedVenueData, setUpdatedVenueData] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalId: "",
    name: "",
    data: null,
  });
  const [isSaving, setIsSaving] = useState(false);
  const { id } = useParams();
  const [initialVales, setInitialValues] = useState({});
  const [emailError, setEmailError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "Employe/getEmployeeDetailsById",
        token: cookies.b_t,
        params: { id },
      });
      setInitialValues(res);
    };

    if (type === "edit") {
      fetchData();
    }
  }, []);

  useEffect(() => {
    (async () => {
      const resDepartment = await getDepartmentList({
        business_id: cookies.bid,
        token: cookies.b_t,
      });
      setDepartment(resDepartment);

      const resDesignation = await getDesignationList({
        business_id: cookies.bid,
        token: cookies.b_t,
      });
      setDesignation(resDesignation);

      let resVenue;

      if (mode === MODE_BUSINESS) {
        resVenue = await getVenueList({
          business_id: cookies.bid,
          token: cookies.b_t,
        });
      } else {
        const res = await getEmployeeVenueList({
          emp_id: cookies.buid,
          token: cookies.b_t,
        });

        resVenue = res.filter((item) => item.venue_admin === true);
      }
      setVenueData(resVenue);
    })();
  }, []);

  useEffect(() => {
    if (type === "edit" && initialVales?.venue_id) {
      const data = venueData?.filter(
        (venue) => venue.value !== initialVales?.venue_id
      );
      setUpdatedVenueData(data);
    }
  }, [initialVales, venueData]);

  const initialData = {
    venue_id: initialVales.venue_id || null,
    employee_id: initialVales.employee_id || null,
    name: initialVales.name || null,
    email: initialVales.email || null,
    phone_number: initialVales.phone_number || null,
    country_code: initialVales.country_code || '+91',
    designation: initialVales.designation || null,
    department: initialVales.department || null,
    two_wheeler_number_1: initialVales.two_wheeler_number_1 || null,
    two_wheeler_number_2: initialVales.two_wheeler_number_2 || null,
    four_wheeler_number_1: initialVales.four_wheeler_number_1 || null,
    four_wheeler_number_2: initialVales.four_wheeler_number_2 || null,
    is_multi_location_invite: initialVales.is_multi_location_invite || null,
    venues: initialVales.multi_venues || [],
    status: initialVales.status || null,
  };
  const validationSchema = yup.object().shape({
    venue_id: yup.string().required("Venue is required"),
    employee_id: yup.mixed().required("Employee Id is required"),
    name: yup.string().required("Employee Name is required"),
    email: yup.string().required("Email is required").email("Invalid email"),
    designation: yup.mixed().required("Designation is required"),
    country_code: yup.string().required("Country code is required"),
    phone_number: yup.string().when("country_code", {
      is: "+91", // Condition: when country_code is '+91'
      then: () =>
        yup
          .string()
          .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
          .nullable(),

      // .required("Primary Phone Number is required"),
      otherwise: () =>
        yup
          .string()
          .matches(/^[0-9]{6,15}$/, "Please Enter a valid Phone Number")
          .nullable(),
      // .required("Primary Phone Number is required"),
    }),
    two_wheeler_number_1: yup
      .string()
      .matches(vehicleNumberRegex, "Invalid Vehicle Number")
      .nullable(),
    two_wheeler_number_2: yup
      .string()
      .matches(vehicleNumberRegex, "Invalid Vehicle Number")
      .nullable(),
    four_wheeler_number_1: yup
      .string()
      .matches(vehicleNumberRegex, "Invalid Vehicle Number")
      .nullable(),
    four_wheeler_number_2: yup
      .string()
      .matches(vehicleNumberRegex, "Invalid Vehicle Number")
      .nullable(),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);

    try {
      const res = await postData({
        endpoint: "Employe/addEmployee",
        token: cookies.b_t,
        data: {
          ...values,
          venues: values.is_multi_location_invite
            ? [...values.venues, values.venue_id]
            : [values.venue_id],
          name: capitalizeLetter(values.name),
        },
        headerType: mode,
      });
      if (res) {
        toast.success("Employee Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      console.log("err", error);
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    setIsSaving(true);

    try {
      const res = await patchData({
        endpoint: "Employe/editEmployee",
        token: cookies.b_t,
        data: {
          ...values,
          venues: values.is_multi_location_invite
            ? [...values.venues]
            : [values.venue_id],
          business_id: cookies.bid,
          name: capitalizeLetter(values.name),
        },
        headerType: mode,
        params: { id: id },
      });
      if (res) {
        toast.success("Employee Details Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const checkEmail = async (newVal) => {
    if (initialVales && newVal !== initialVales.email) {
      const response = await await getData({
        endpoint: "Employe/checkEmail",
        params: { business_id: cookies.bid, email: newVal },
        token: cookies.b_t,
      });
      if (response.response !== 200) {
        setEmailError("Email Already Exists");
      } else {
        setEmailError(null);
      }
    } else {
      setEmailError(null);
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
      validateOnMount
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
      }) => (
        <>
          <PageHeader title={type === "add" ? "Add Employee" : "Edit Employee"}>
            <BackBtn />
          </PageHeader>

          <PageCard>
            <div className="row g-4">
              <InputLayout>
                <label htmlFor="venue_id" className='required-field'>Select Venue</label>
                <SelectDropdown
                  placeholder="Select Venue"
                  data={venueData}
                  handleBlur={(e) => {
                    setFieldTouched("venue_id", true);
                    handleBlur(e);
                  }}
                  onChange={(option) => {
                    setFieldValue("venue_id", option);
                    setUpdatedVenueData(
                      venueData?.filter((venue) => venue.value !== option)
                    );
                  }}
                  isError={
                    type === "add"
                      ? touched.venue_id && errors.venue_id
                      : errors.venue_id
                  }
                  selectedValue={initialData?.venue_id}
                  disabled={type === "edit"}
                />
                {type === "add"
                  ? touched.venue_id &&
                    errors.venue_id && <Error error={errors.venue_id} />
                  : errors.venue_id && <Error error={errors.venue_id} />}
              </InputLayout>

              <InputLayout>
                <label htmlFor="employee_id" className='required-field'>Employee Id</label>
                <Field
                  type="text"
                  id="employee_id"
                  name="employee_id"
                  placeholder="Enter Employee Id"
                  className={
                    type === "add"
                      ? touched.employee_id && errors.employee_id
                        ? "error-input"
                        : ""
                      : errors.employee_id
                      ? "error-input"
                      : ""
                  }
                />
                {type === "add"
                  ? touched.employee_id &&
                    errors.employee_id && <Error error={errors.employee_id} />
                  : errors.employee_id && <Error error={errors.employee_id} />}
              </InputLayout>

              <InputLayout>
                <label htmlFor="employee_name" className='required-field'>Employee Name</label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Employee Name"
                  className={
                    type === "add"
                      ? touched.name && errors.name
                        ? "error-input"
                        : ""
                      : errors.name
                      ? "error-input"
                      : ""
                  }
                />
                {type === "add"
                  ? touched.name && errors.name && <Error error={errors.name} />
                  : errors.name && <Error error={errors.name} />}
              </InputLayout>
            </div>

            <div className="row g-4 mt-2">
              <InputLayout>
                <label htmlFor="email" className='required-field'>Email Id</label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  className={
                    type === "add"
                      ? (touched.email && errors.email) || emailError
                        ? "error-input"
                        : ""
                      : errors.email || emailError
                      ? "error-input"
                      : ""
                  }
                  onBlur={(e) => {
                    checkEmail(values.email);
                    handleBlur(e);
                  }}
                />
                {type === "add"
                  ? ((touched.email && errors.email) || emailError) && (
                      <Error error={errors.email || emailError} />
                    )
                  : (errors.email || emailError) && (
                      <Error error={errors.email || emailError} />
                    )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="phone_number">Phone Number</label>
                <div className="d-flex gap-3">

                     <div style={{ width: "130px" }}>
                      <CountryCodeDropdown
                        selectedValue={values.country_code}
                        onChange={(code) => setFieldValue("country_code", code)}
                        isError={touched.country_code && !!errors.country_code}
                        classes="custom-dropdown-style"
                        isClearable={true}
                      />
                    </div>
                  <div
                    className="col-lg-10 pe-0 col-9"
                    style={{ flex: "auto" }}
                  >
                    <Field
                      type="text"
                      name="phone_number"
                      placeholder="Enter Phone Number"
                      className={`w-100 h-100 ${
                        touched.phone_number && errors.phone_number
                          ? "error-input"
                          : ""
                      }`}
                      onChange={(e) => {
                        let numericInput = e.target.value.replace(
                          /[^0-9]/g,
                          ""
                        );
                        if (values.country_code === "+91") {
                          if (numericInput.length > 10) {
                            numericInput = numericInput.slice(0, 10);
                          }
                          setFieldValue("phone_number", numericInput);
                        } else {
                          setFieldValue("phone_number", numericInput);
                        }
                      }}
                    />
                  </div>
                </div>
                {type === "add"
                  ? touched.phone_number &&
                    errors.phone_number && <Error error={errors.phone_number} />
                  : errors.phone_number && (
                      <Error error={errors.phone_number} />
                    )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="designation" className='required-field'>Designation</label>
                <SelectDropdown
                  placeholder="Select Designation"
                  format="all"
                  data={designation}
                  handleBlur={(e) => {
                    setFieldTouched("designation", true);
                    handleBlur(e);
                  }}
                  onChange={(option) => {
                    setFieldValue("designation", option.label);
                  }}
                  isError={
                    type === "add"
                      ? touched.designation && errors.designation
                      : errors.designation
                  }
                  selectedValue={initialData?.designation}
                />
                {type === "add"
                  ? touched.designation &&
                    errors.designation && <Error error={errors.designation} />
                  : errors.designation && <Error error={errors.designation} />}
              </InputLayout>
            </div>

            <div className="row g-4 mt-2">
              <InputLayout>
                <label htmlFor="department">Department</label>
                <SelectDropdown
                  placeholder="Select Department"
                  data={department}
                  format="all"
                  handleBlur={(e) => {
                    setFieldTouched("department", true);
                    handleBlur(e);
                  }}
                  onChange={(option) => {
                    setFieldValue("department", option.label);
                  }}
                  // isError={touched.department && errors.department}
                  selectedValue={initialData?.department}
                />
              </InputLayout>

              <InputLayout>
                <label htmlFor="two_wheeler_number_1">
                  Two Wheeler Number 1(If Any)
                </label>
                <Field
                  type="text"
                  id="two_wheeler_number_1"
                  name="two_wheeler_number_1"
                  placeholder="Two Wheeler Number"
                  className={
                    type === "add"
                      ? touched.two_wheeler_number_1 &&
                        errors.two_wheeler_number_1
                        ? "error-input"
                        : ""
                      : errors.two_wheeler_number_1
                      ? "error-input"
                      : ""
                  }
                />
                {type === "add"
                  ? touched.two_wheeler_number_1 &&
                    errors.two_wheeler_number_1 && (
                      <Error error={errors.two_wheeler_number_1} />
                    )
                  : errors.two_wheeler_number_1 && (
                      <Error error={errors.two_wheeler_number_1} />
                    )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="two_wheeler_number_2">
                  Two Wheeler Number 2(If Any)
                </label>
                <Field
                  type="text"
                  id="two_wheeler_number_2"
                  name="two_wheeler_number_2"
                  placeholder="Two Wheeler Number"
                  className={
                    type === "add"
                      ? touched.two_wheeler_number_2 &&
                        errors.two_wheeler_number_2
                        ? "error-input"
                        : ""
                      : errors.two_wheeler_number_2
                      ? "error-input"
                      : ""
                  }
                />
                {type === "add"
                  ? touched.two_wheeler_number_2 &&
                    errors.two_wheeler_number_2 && (
                      <Error error={errors.two_wheeler_number_2} />
                    )
                  : errors.two_wheeler_number_2 && (
                      <Error error={errors.two_wheeler_number_2} />
                    )}
              </InputLayout>
            </div>

            <div className="row g-4 mt-2">
              <InputLayout>
                <label htmlFor="four_wheeler_number_1">
                  Four Wheeler Number 1(If Any)
                </label>
                <Field
                  type="text"
                  id="four_wheeler_number_1"
                  name="four_wheeler_number_1"
                  placeholder="Four Wheeler Number"
                  className={
                    type === "add"
                      ? touched.four_wheeler_number_1 &&
                        errors.four_wheeler_number_1
                        ? "error-input"
                        : ""
                      : errors.four_wheeler_number_1
                      ? "error-input"
                      : ""
                  }
                />
                {type === "add"
                  ? touched.four_wheeler_number_1 &&
                    errors.four_wheeler_number_1 && (
                      <Error error={errors.four_wheeler_number_1} />
                    )
                  : errors.four_wheeler_number_1 && (
                      <Error error={errors.four_wheeler_number_1} />
                    )}
              </InputLayout>

              <InputLayout>
                <label htmlFor="four_wheeler_number_2">
                  Four Wheeler Number 2(If Any)
                </label>
                <Field
                  type="text"
                  id="four_wheeler_number_2"
                  name="four_wheeler_number_2"
                  placeholder="Four Wheeler Number"
                  className={
                    type === "add"
                      ? touched.four_wheeler_number_2 &&
                        errors.four_wheeler_number_2
                        ? "error-input"
                        : ""
                      : errors.four_wheeler_number_2
                      ? "error-input"
                      : ""
                  }
                />
                {type === "add"
                  ? touched.four_wheeler_number_2 &&
                    errors.four_wheeler_number_2 && (
                      <Error error={errors.four_wheeler_number_2} />
                    )
                  : errors.four_wheeler_number_2 && (
                      <Error error={errors.four_wheeler_number_2} />
                    )}
              </InputLayout>

              {type === "edit" && initialVales.status !== "Pending" && (
                <InputLayout>
                  <label htmlFor="">Status</label>
                  <SelectDropdown
                    data={majorStatus}
                    placeholder="Select Status"
                    onChange={(option) => {
                      setFieldValue("status", option);
                    }}
                    selectedValue={initialData?.status}
                  />
                </InputLayout>
              )}
            </div>

            <div className="checkbox_container mt-4">
              <label htmlFor={`is_multi_location_invite`}>
                Employee is Allowed for Multiple Location Invite?
              </label>
              <CheckboxWrapper
                type="checkbox"
                id="is_multi_location_invite"
                name="is_multi_location_invite"
                value={values.is_multi_location_invite}
                checked={values.is_multi_location_invite}
                onChange={() => {
                  const updated = values.is_multi_location_invite;
                  setFieldValue("is_multi_location_invite", !updated);
                  if (!updated) {
                    setModalState((prevState) => ({
                      ...prevState,
                      isModalOpen: true,
                      modalId: initialData?.id,
                      data: venueData.filter(
                        (venue) => venue.value !== values.venue_id
                      ),
                    }));
                  }
                }}
                onBlur={(e) => {
                  handleBlur(e);
                }}
              />
            </div>

            {values?.is_multi_location_invite &&
              updatedVenueData.length > 0 && (
                <VenueDataSElection
                  data={updatedVenueData}
                  handleAddVenue={(venues) => {
                    setFieldValue("venues", venues);
                  }}
                  selectedData={
                    initialData?.venues?.length > 0 ? initialData?.venues : []
                  }
                />
              )}

            <div className="d-flex gap-3 mt-4">
              {type === "add" ? (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleSubmit(values)}
                  disabled={!isValid || !dirty || isSaving || emailError}
                >
                  Add Employee
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              ) : (
                <CustomButton
                  iconRequired={false}
                  type="btn-primary"
                  buttonType="submit"
                  handleClick={() => handleEdit(values)}
                  disabled={!isValid || isSaving || emailError}
                >
                  Update
                  {isSaving && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </CustomButton>
              )}

              <CustomButton
                text="Cancel"
                iconRequired={false}
                handleClick={() => {
                  navigate(-1);
                }}
              />
            </div>
          </PageCard>

          {/* {modalState.isModalOpen && (
            <Modal
              isActive={modalState.isModalOpen}
              onClose={() =>
                setModalState((prevState) => ({
                  ...prevState,
                  isModalOpen: false,
                }))
              }
              title="Select Multiple Venues"
            > */}

          {/* </Modal> */}
          {/* )} */}
        </>
      )}
    </Formik>
  );
};

export default Add;
