import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../../../services";
import SelectDropdown from "../../../../../molecules/Dropdown";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../../assets/styles/toast";
import TagInput from "../../../../../molecules/TagInput";
import InputLayout from "../../../../../atoms/InputLayout";
import CustomTimePicker from "../../../../../organisms/TimePicker";
import dayjs from "dayjs";
import Error from "../../../../../atoms/Error";
import PageHeader from "../../../../../atoms/PageHeader";
import ColorPicker from "../../../../../atoms/ColorPicker";

const Settings = ({ initialValues, id, refetchData }) => {
  const [cookies] = useCookies(["b_t", "bid"]);
  const [data, setData] = useState();

  const settingsData = async () => {
    const [res, res2] = await Promise.all([
      getData({
        endpoint: "BusinessConfiguration/getGeneralSetting",
        params: { business_id: cookies.bid },
        token: cookies.b_t,
      }),
      getData({
        endpoint: "BusinessConfiguration/getEmpAppTheme",
        params: { business_id: cookies.bid },
        token: cookies.b_t,
      }),
    ]);

    setData({ ...res, ...res2?.data });
  };

  useEffect(() => {
    settingsData();
  }, []);

  const initialData = {
    emails: data?.emails || null,
    time: data?.time || null,
    tnc: data?.tnc || null,
    privacy_policy: data?.privacy_policy || null,
    primary_color: data?.emp_app_primary_theme_color || '#290351',
    secondary_color: data?.emp_app_seconddary_theme_color || '#2979FF',
  };

  const validationSchema = Yup.object({
    time: Yup.string().required("Email Sending Time is Required"),
    emails: Yup.array()
      .of(Yup.string().required("Each Department name is required").email(true))
      .min(1, "At least one Email is required")
      .required("At least one Email is required"),
    // tnc: Yup.string().url("Invalid URL"),
    // privacy_policy: Yup.string().url("Invalid URL"),
  });

  const handleSubmit = async (values) => {
    try {
      const [res, res2] = await Promise.all([
        patchData({
          endpoint: "BusinessConfiguration/updateGeneralSetting",
          token: cookies.b_t,
          data: { ...values, business_id: cookies.bid },
        }),
        postData({
          endpoint: "BusinessConfiguration/updateEmpAppTheme",
          token: cookies.b_t,
          params: {
            business_id: cookies.bid,
            primary_color: values?.primary_color,
            secondary_color: values?.secondary_color,
          },
        }),
      ]);

      if (res && res2) {
        toast.success("Settings Added Successfully", {
          style: confimationStyles,
          duration: 1000,
        });

        settingsData();
      }
    } catch (error) {}
  };

  const compareDates = (date) => {
    if (date) {
      const givenDate = dayjs(date).startOf("day");
      const currentDate = dayjs().startOf("day");

      if (givenDate.isBefore(currentDate) || givenDate.isAfter(currentDate)) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialData}
        validationSchema={validationSchema}
        key={id}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({
          dirty,
          isValid,
          values,
          errors,
          touched,
          setFieldTouched,
          setFieldValue,
          handleBlur,
          handleChange,
        }) => (
          <Form>
            <PageHeader title="Daily Report" type="small" className="mb-0">
              <button
                type="button"
                className="btn btn-primary"
                disabled={!isValid}
                onClick={() => handleSubmit(values)}
              >
                Save Changes
              </button>
            </PageHeader>
            <InputLayout>
              <label htmlFor="name">Emails</label>
              {console.log("err", errors)}
              <TagInput
                placeholder="Add emails and press enter"
                onChange={(values) => {
                  setFieldValue("emails", values);
                }}
                maxTags={5}
                errors={
                  Array.isArray(errors?.emails) && errors.emails.length > 0
                    ? errors.emails
                        .map((value, index) => (value ? index : -1))
                        .filter((index) => index !== -1)
                    : []
                }
                selectedTags={values.emails}
                styles={{ minHeight: "48px" }}
              />
            </InputLayout>

            <div className="row">
              <div className="col-3 mt-3">
                <InputLayout>
                  <label htmlFor="">Email Sending Time</label>

                  <CustomTimePicker
                    placeholder="Email Sending Time"
                    handleBlur={(e) => {
                      setFieldTouched("time", true);
                      handleBlur(e);
                    }}
                    isError={touched.time && errors.time}
                    onChange={(date) => setFieldValue("time", date)}
                    selectedTime={initialData?.time}
                  />
                  {touched.time && errors.time && <Error error={errors.time} />}
                </InputLayout>
              </div>
            </div>

            <hr />

            <PageHeader
              title={"Employee App Theme"}
              type="small"
              className="mb-0"
            />

            <div className=" row g-4 mb-3">
              <InputLayout>
                <label htmlFor="primary_color"> Background Color</label>

                <ColorPicker
                  onChange={(color) => setFieldValue("primary_color", color)}
                  selected={values.primary_color}
                />
                {touched.primary_color && errors.primary_color && (
                  <Error error={errors.primary_color} />
                )}
              </InputLayout>
              <InputLayout>
                <label htmlFor="secondary_color">Component Theme Color</label>
                <ColorPicker
                  onChange={(color) => setFieldValue("secondary_color", color)}
                  selected={values.secondary_color}
                />
                {touched.secondary_color && errors.secondary_color && (
                  <Error error={errors.secondary_color} />
                )}
              </InputLayout>
            </div>

            <hr />

            <PageHeader
              title="Legal Agreements

"
              type="small"
              className="mb-0"
            />

            <div className="row">
              <InputLayout>
                <label htmlFor="name">Terms & Conditions</label>
                {console.log("err", errors)}
                <Field
                  placeholder="Terms & Conditions"
                  name="tnc"
                  id="tnc"
                  type="text"
                />
              </InputLayout>
              <InputLayout>
                <label htmlFor="name">Privacy Policy</label>

                <Field
                  placeholder="Privacy Policy"
                  name="privacy_policy"
                  id="privacy_policy"
                  type="text"
                />
              </InputLayout>
            </div>

            <hr />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Settings;
