import React, { useEffect, useState, useRef, useCallback } from "react";
import PageHeader from "../../../atoms/PageHeader";
import { getData } from "../../../../services/index";
import {
  getEmployeeVenueList,
  getVenueList,
} from "../../../../services/basicGets";
import InfoCard from "../../../organisms/InfoCards";
import DateRangePickerShortCuts from "../../../organisms/dateRangePicker/index";
import { useCookies } from "react-cookie";
import Visit from "../../../icons/Visit";
import People from "../../../icons/People";
import CheckOut from "../../../icons/CheckOut";
import CheckIn from "../../../icons/CheckIn";
import VisitorChart from "./VisitorChart";
import SelectDropdown from "../../../molecules/Dropdown";
import { useNavigate } from "react-router-dom";
import VisitorLogs from "./VisitorLogs";
import addMonths from "date-fns/addMonths";
import UpcomingCard from "./ItemCards/UpcomingCard";
import CheckInCard from "./ItemCards/CheckedInCard";
import NoContentCard from "../../../atoms/NoContentCard";
import NoContentIcon from "../../../icons/NocontentIcon";

import {
  addHours,
  startOfDay,
  startOfMonth,
  endOfMonth,
} from "rsuite/esm/internals/utils/date";

const Home = () => {
  const [cookies] = useCookies(["b_t", "bid", "mode", "buid"]);
  const navigate = useNavigate();

  const [modalStates, setModalStates] = useState({
    upcoming: null,
    current: null,
    check_in: null,
    check_out: null,
  });
  const [switchedTab, setSwitchedTab] = useState();

  const defaultToday = new Date();
  const [dashboardData, setDashboardData] = useState();

  
  const [selectedVenue, setSelectedVenue] = useState("All");
  const [date, setDate] = useState({
    start_date: addHours(startOfDay(startOfMonth(defaultToday)), 12),
    end_date: addHours(startOfDay(endOfMonth(defaultToday)), 12),
  });
  const [venueData, setVenueData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const myRef = useRef(null);

  const fetchVenueList = useCallback(async () => {
    try {
      const params = {
        business_id: cookies.bid,
        employee_id:
          atob(cookies?.mode || "") === "Employee" ? cookies.buid : null,
        token: cookies.b_t,
      };
      if (atob(cookies?.mode || "") === "Employee") {
        params.emp_id = cookies.buid;
      }
      const resVenue =
        atob(cookies?.mode) === "Employee"
          ? await getEmployeeVenueList({
              emp_id: cookies.buid,
              token: cookies.b_t,
            })
          : await getVenueList(params);

      if (resVenue.length > 1) {
        setVenueData([{ value: "All", label: "All Venues" }, ...resVenue]);
      } else {
        setVenueData(resVenue);
        setSelectedVenue(resVenue?.[0]?.value);
      }
    } catch (err) {
      console.error(err);
    }
  }, [cookies]);

  const fetchDashboardData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "BusinessDashboard/getBusinessDashboardData",
        params: {
          start_date: date.start_date,
          end_date: date.end_date,
          business_id: cookies.bid,
          employee_id:
            atob(cookies?.mode || "") === "Employee" ? cookies.buid : null,
          venues:
            selectedVenue === "All"
              ? atob(cookies.mode) === "Employee"
                ? JSON.stringify(
                    venueData
                      ?.filter((item) => item.label !== "All Venues")
                      ?.map((item) => item.value)
                  )
                : null
              : selectedVenue
              ? JSON.stringify([selectedVenue])
              : null,
        },
        token: cookies.b_t,
      });
      if (res) {
        setDashboardData(res);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [date, selectedVenue, cookies, venueData]);

  useEffect(() => {
    fetchVenueList();
  }, [fetchVenueList]);

  useEffect(() => {
    if (venueData) {
      fetchDashboardData();
    }
  }, [date, selectedVenue, fetchDashboardData, venueData]);

  const scrollToElement = () => {
    return new Promise((resolve) => {
      if (myRef.current) {
        myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        setTimeout(resolve, 500);
      } else {
        resolve();
      }
    });
  };

  const renderInfoCard = useCallback(
    ({
      color,
      title,
      tabTitle,
      data,
      isSvg,
      svgIcon,
      directClick = false,
      OnClickAction = () => {},
      modalStateKey,
      children,
    }) => (
      <InfoCard
        key={title}
        color={color}
        title={title}
        data={data}
        isSvg={isSvg}
        directClick={directClick}
        OnClickAction={OnClickAction}
        svgIcon={svgIcon}
        modalActive={modalStates[modalStateKey]}
        modalActiveState={(state) =>
          setModalStates((prev) => ({ ...prev, [modalStateKey]: state }))
        }
      >
        {data > 0 ? (
          <>
            {children}
            <div
              className="d-flex justify-content-end"
              onClick={async () => {
                if (tabTitle === "Total") {
                  atob(cookies.mode) === "Employee"
                    ? navigate("/dashboard/employee/visitor-history")
                    : navigate("/dashboard/business/activities");
                } else if (tabTitle === "History")
                  atob(cookies.mode) === "Employee"
                    ? navigate("/dashboard/employee/visitor-history", {
                        state: {
                          statusCompleted: {
                            label: "Completed",
                            value: "Completed",
                          },
                        },
                      })
                    : navigate("/dashboard/business/activities", {
                        state: {
                          statusCompleted: {
                            label: "Completed",
                            value: "Completed",
                          },
                        },
                      });
                else {
                  setModalStates((prev) => ({
                    ...prev,
                    [modalStateKey]: false,
                  }));
                  await scrollToElement();
                  setSwitchedTab(tabTitle);
                }
              }}
            >
              <div className="btn-link btn-link-primary cursor-pointer fw-bold mb-1">
                View All
              </div>
            </div>
          </>
        ) : (
          <NoContentCard
            title="No Record Found"
            type="Company"
            icon={<NoContentIcon />}
            small={true}
          />
        )}
      </InfoCard>
    ),
    [modalStates, scrollToElement]
  );

  const infoCardConfigs = [
    {
      color: "#FFBC99",
      title: "Upcoming Visits",
      tabTitle: "Upcoming Visits",
      data: dashboardData?.upcoming_visitors?.total_record,
      isSvg: true,
      svgIcon: <Visit />,
      modalStateKey: "upcoming",
      children: dashboardData?.upcoming_visitors?.data.map((item) => (
        <UpcomingCard item={item} key={item.id} />
      )),
    },
    {
      color: "#B1E5FC",
      title: "Current Visitors",
      tabTitle: "Current Visitors",
      data: dashboardData?.current_visitors?.total_record,
      isSvg: true,
      svgIcon: <People />,
      modalStateKey: "current",
      children: dashboardData?.current_visitors?.data?.map((item) => (
        <CheckInCard item={item} key={item.id} type="checkin" />
      )),
    },
    {
      color: "#FFB7B7",
      title: "Checked Out Visitors",
      tabTitle: "History",
      data: dashboardData?.checked_out_visitors?.total_record,
      isSvg: true,
      svgIcon: <CheckOut />,
      modalStateKey: "check_out",
      children: dashboardData?.checked_out_visitors?.data?.map((item) => (
        <CheckInCard item={item} key={item.id} type="checkout" />
      )),
    },
    {
      color: "#FFD88D",
      title: "Total Checked In Visitors",
      tabTitle: "Total",
      data: dashboardData?.check_in_visits?.total_record,
      isSvg: true,
      svgIcon: <CheckIn />,
      modalStateKey: "check_in",
      children: dashboardData?.check_in_visits?.data?.map((item) => (
        <CheckInCard item={item} key={item.id} type="checkin" />
      )),
    },
  ];

  return (
    <>
      <PageHeader title="Home">
        <div className="d-flex gap-3">
          <SelectDropdown
            type="dashboard"
            placeholder="Select Venue"
            data={venueData}
            selectedValue={selectedVenue}
            onChange={(option) => setSelectedVenue(option)}
          />
          <DateRangePickerShortCuts setDate={setDate} />
        </div>
      </PageHeader>

      <div className="d-flex flex-wrap gap-4">
        {infoCardConfigs.map((config) => renderInfoCard(config))}
      </div>

      <div className="mt-4">
        <VisitorChart venueData={venueData} />
      </div>

      <div className="mt-4" id="visitorlogs" ref={myRef}>
        <VisitorLogs
          switchedTab={switchedTab}
          venueData={venueData}
          selectedVenue={selectedVenue}
        />
      </div>
    </>
  );
};

export default Home;
