import { useState, useEffect, useRef } from "react";
import { SketchPicker } from "react-color";
import "./styles.scss";

const ColorPicker = ({ onChange, selected }) => {
  const defaultColor = "#6B66DA"; // Default color
  const [selectedColor, setSelectedColor] = useState(selected || defaultColor); // Initial color
  const [showPicker, setShowPicker] = useState(false);
  const [inputColor, setInputColor] = useState(selected || defaultColor); // State for the input field
  const pickerRef = useRef(null); // Create a ref for the picker

  // Sync selected color with input color when `selected` prop changes
  useEffect(() => {
    if (selected) {
      setSelectedColor(selected);
      setInputColor(selected);
    } else {
      setSelectedColor(defaultColor);
      setInputColor(defaultColor);
    }
  }, [selected]);

  // Function to handle color change from the color picker
  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    setInputColor(color.hex); // Sync input field with the new color
    onChange(color.hex); // Propagate the change to the parent component
  };

  // Function to handle input change
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputColor(value); // Update the input field

    // If the input is empty, reset to the default color
    if (value === "") {
      setSelectedColor('#ffffff');
      onChange('#ffffff');
    }
    // Validate the hex color and update the selected color if valid
    else if (/^#([0-9A-Fa-f]{3}){1,2}$/.test(value)) {
      setSelectedColor(value);
      onChange(value); // Propagate the change to the parent component
    }
  };

  // Function to close the picker when clicked outside
  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowPicker(false);
    }
  };

  // Attach event listener to detect clicks outside
  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on component unmount or when showPicker changes
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);

  return (
    <div className="color-picker-container">
      <div className="d-flex align-items-center color-data-container">
        <div
          className="selected-color-preview"
          style={{ backgroundColor: selectedColor }}
          onClick={() => setShowPicker(!showPicker)} // Open picker on click
        ></div>
        <input
          type="text"
          value={inputColor}
          onChange={handleInputChange}
          placeholder="Enter hex color"
          className="color-input"
        />
      </div>

      {showPicker && (
        <div ref={pickerRef}>
          <SketchPicker
            color={selectedColor}
            onChangeComplete={handleColorChange}
          />
        </div>
      )}
    </div>
  );
};

export default ColorPicker;