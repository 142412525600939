import React, { useEffect, useState } from "react";
import { Form, Formik, Field } from "formik";
import * as yup from "yup";

import PageCard from "../../../atoms/PageCard";
import PageHeader from "../../../atoms/PageHeader";
import Error from "../../../atoms/Error";
import InputLayout from "../../../atoms/InputLayout";
import SelectDropdown from "../../../molecules/Dropdown";
import { countryCodeData } from "../../../../helpers/countryCodes";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getData, patchData } from "../../../../services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import { capitalizeLetter } from "../../../../helpers/capatalizeLetters";
import { vehicleNumberRegex } from "../../../../helpers/regex";
import CountryCodeDropdown from "../../../molecules/CountryCodeDropdown";

const EmployeeEditProfile = () => {
  const [cookies] = useCookies(["buid", "bid"]);
  const [data, setData] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await getData({
        endpoint: "Employe/getEmployeeDetailsById",
        token: cookies.b_t,
        params: { id: cookies.buid },
      });
      setData(res);
    })();
  }, []);

  const navigate = useNavigate();
  const initialValues = {
    venue_id: data?.venue_id || null,
    employee_id: data?.employee_id || null,
    name: data?.name || null,
    email: data?.email || null,
    phone_number: data?.phone_number || null,
    country_code: data?.country_code || "+91",
    designation: data?.designation || null,
    department: data?.department || null,
    two_wheeler_number_1: data?.two_wheeler_number_1 || null,
    two_wheeler_number_2: data?.two_wheeler_number_2 || null,
    four_wheeler_number_1: data?.four_wheeler_number_1 || null,
    four_wheeler_number_2: data?.four_wheeler_number_2 || null,
    is_multi_location_invite: data?.is_multi_location_invite || null,
    venues: data?.multi_venues || [],
    is_active: data?.status || false,
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Employee Name is required"),
    phone_number: yup.string().when("country_code", {
      is: "+91", // Condition: when country_code is '+91'
      then: () =>
        yup
          .string()
          .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
          .nullable()
          .required("Phone Number is required"),
      otherwise: () =>
        yup
          .string()
          .matches(/^[0-9]{6,15}$/, "Please Enter a valid Phone Number")
          .nullable()
          .required("Phone Number is required"),
    }),
    two_wheeler_number_1: yup
      .string()
      .matches(vehicleNumberRegex, "Invalid Vehicle Number")
      .nullable(),
    two_wheeler_number_2: yup
      .string()
      .matches(vehicleNumberRegex, "Invalid Vehicle Number")
      .nullable(),
    four_wheeler_number_1: yup
      .string()
      .matches(vehicleNumberRegex, "Invalid Vehicle Number")
      .nullable(),
    four_wheeler_number_2: yup
      .string()
      .matches(vehicleNumberRegex, "Invalid Vehicle Number")
      .nullable(),
  });

  const handleSubmit = async (values) => {
    try {
      const res = await patchData({
        endpoint: "Employe/editEmployee",
        token: cookies.b_t,
        data: {
          ...values,
          venues: [...values.venues, values.venue_id],
          business_id: cookies.bid,
          name: capitalizeLetter(values.name),
        },
        headerType: "Employee",
        params: { id: cookies.buid },
      });
      if (res) {
        toast.success("Details Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
      }
    } catch (error) {
      console.log("err", error);
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        handleSubmit,
        touched,
        errors,
        values,
        isValid,
        dirty,
        handleBlur,
        setFieldValue,
        resetForm,
      }) => (
        <>
          <PageHeader title="Edit Profile" />

          <PageCard classes="w-100">
            <Form onSubmit={handleSubmit}>
              <div className="row g-4">
                <InputLayout>
                  <label htmlFor="name">Name</label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter Name"
                    className={`${
                      touched.name && errors.name ? "error-input" : ""
                    }`}
                  />
                  {touched.name && errors.name && <Error error={errors.name} />}
                </InputLayout>

                <InputLayout>
                  <label htmlFor="empId">Employee Id</label>
                  <Field
                    type="text"
                    id="employee_id"
                    name="employee_id"
                    placeholder="Enter Employee Id"
                    className={`${
                      touched.employee_id && errors.employee_id
                        ? "error-input"
                        : ""
                    }`}
                    disabled
                  />
                  {touched.employee_id && errors.employee_id && (
                    <Error error={errors.employee_id} />
                  )}
                </InputLayout>

                <InputLayout>
                  <label htmlFor="email">Email Id</label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    disabled
                  />
                </InputLayout>
              </div>

              <div className="row g-4 mt-1">
                <InputLayout>
                  <label htmlFor="phone_number">Phone Number</label>
                  <div className="d-flex gap-3">
                    <div style={{ width: "130px" }}>
                      <CountryCodeDropdown
                        selectedValue={values.country_code}
                        onChange={(code) => setFieldValue("country_code", code)}
                        isError={touched.country_code && !!errors.country_code}
                        classes="custom-dropdown-style"
                        isClearable={true}
                      />
                    </div>

                    <div
                      className="col-lg-10 pe-0 col-9"
                      style={{ flex: "auto" }}
                    >
                      <Field
                        type="text"
                        name="phone_number"
                        placeholder="Enter Phone Number"
                        className={`w-100 h-100 ${
                          touched.phone_number && errors.phone_number
                            ? "error-input"
                            : ""
                        }`}
                        onChange={(e) => {
                          let numericInput = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                          if (values.country_code === "+91") {
                            if (numericInput.length > 10) {
                              numericInput = numericInput.slice(0, 10);
                            }
                            setFieldValue("phone_number", numericInput);
                          } else {
                            setFieldValue("phone_number", numericInput);
                          }
                        }}
                      />
                    </div>
                  </div>
                  {touched.phone_number &&
                    errors.phone_number && (
                      <Error error={errors.phone_number} />
                    ) && <Error error={errors.phone_number} />}
                </InputLayout>

                <InputLayout>
                  <label htmlFor="designation">Designation</label>
                  <Field
                    type="text"
                    id="designation"
                    name="designation"
                    placeholder="Enter Designation"
                    className={`${
                      touched.designation && errors.designation
                        ? "error-input"
                        : ""
                    }`}
                    disabled
                  />
                  {touched.designation && errors.designation && (
                    <Error error={errors.designation} />
                  )}
                </InputLayout>

                <InputLayout>
                  <label htmlFor="department">Department</label>
                  <Field
                    type="text"
                    id="department"
                    name="department"
                    placeholder="Enter Department"
                    className={`${
                      touched.department && errors.department
                        ? "error-input"
                        : ""
                    }`}
                    disabled
                  />
                  {touched.department && errors.department && (
                    <Error error={errors.department} />
                  )}
                </InputLayout>
              </div>

              <div className="row g-4 mt-1"></div>

              <div className="row g-4 mt-1">
                <InputLayout>
                  <label htmlFor="two_wheeler_number_1">
                    Two Wheeler Number 1(If Any)
                  </label>
                  <Field
                    type="text"
                    id="two_wheeler_number_1"
                    name="two_wheeler_number_1"
                    placeholder="Two Wheeler Number"
                    className={errors.two_wheeler_number_1 ? "error-input" : ""}
                  />
                  {errors.two_wheeler_number_1 && (
                    <Error error={errors.two_wheeler_number_1} />
                  )}
                </InputLayout>

                <InputLayout>
                  <label htmlFor="two_wheeler_number_2">
                    Two Wheeler Number 2(If Any)
                  </label>
                  <Field
                    type="text"
                    id="two_wheeler_number_2"
                    name="two_wheeler_number_2"
                    placeholder="Two Wheeler Number"
                    className={errors.two_wheeler_number_2 ? "error-input" : ""}
                  />
                  {errors.two_wheeler_number_2 && (
                    <Error error={errors.two_wheeler_number_2} />
                  )}
                </InputLayout>

                <InputLayout>
                  <label htmlFor="four_wheeler_number_1">
                    Four Wheeler Number 1(If Any)
                  </label>
                  <Field
                    type="text"
                    id="four_wheeler_number_1"
                    name="four_wheeler_number_1"
                    placeholder="Four Wheeler Number"
                    className={
                      errors.four_wheeler_number_1 ? "error-input" : ""
                    }
                  />
                  {errors.four_wheeler_number_1 && (
                    <Error error={errors.four_wheeler_number_1} />
                  )}
                </InputLayout>
              </div>

              <div className="row g-4 mt-1">
                <InputLayout>
                  <label htmlFor="four_wheeler_number_2">
                    Four Wheeler Number 2(If Any)
                  </label>
                  <Field
                    type="text"
                    id="four_wheeler_number_2"
                    name="four_wheeler_number_2"
                    placeholder="Four Wheeler Number"
                    className={
                      errors.four_wheeler_number_2 ? "error-input" : ""
                    }
                  />
                  {errors.four_wheeler_number_2 && (
                    <Error error={errors.four_wheeler_number_2} />
                  )}
                </InputLayout>
                <InputLayout></InputLayout>
                <InputLayout></InputLayout>
              </div>

              <div className="d-flex justify-content-end gap-3">
                <button
                  className="btn btn-primary w-10 mt-4"
                  type="submit"
                  disabled={!isValid || !dirty || isSubmitting}
                >
                  Update
                  {isSubmitting && (
                    <span
                      className="spinner-border spinner-border-sm ms-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>

                <button
                  className="btn btn-outline-dark w-10 mt-4"
                  type="button"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </PageCard>
        </>
      )}
    </Formik>
  );
};

export default EmployeeEditProfile;
