import { useEffect } from "react";
import { postData, postFormData } from "../../../../../services";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/toast";
import { errorStyles } from "../../../../molecules/Dropdown/dropdown";
import { useLocation, useParams } from "react-router-dom";
import QRCode from "qrcode";
import { base64ToFile } from "../../../../../helpers/base64ToFile";

const AcceptInvite = () => {
  const location = useLocation();

  const getQueryParams = (param) => {
    return new URLSearchParams(location.search).get(param);
  };

  const inviteId = getQueryParams("invite_id");
  const barcode = getQueryParams("barcode");

  const generateQrCode = async (string) => {
    try {
      const url = await QRCode.toDataURL(string);

      const finalQr = base64ToFile(url);

      return finalQr;
    } catch (err) {
      console.error(err);
    }
  };

  const handleConfirmStatus = async () => {
    const qr = await generateQrCode(barcode);

    try {
      const res = await postFormData({
        endpoint: "Invite/respondToInvitation",
        params: {
          status: "Accept",
        },
        data: {
          invite_id: inviteId,
          qr_image: qr,
        },
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // const checkStatus = checkConfirmStatus();
    // if (checkStatus.status) {
    handleConfirmStatus();
    // }
  }, []);

  return (
    <div
      className=" d-flex  justify-content-center"
      style={{
        height: "100vh",
        width: "100vw",
        fontSize: "24px",
        fontWeight: "500",
        alignItems: "center",
      }}
    >
      Your Invitation Is Accepted
    </div>
  );
};
export default AcceptInvite;
