import React from "react";
import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <span>
        Copyright © 2025 |{" "}
        <a href="/" class={styles.blue}>
          VisitorXsys.
        </a>{" "}
        All rights reserved.{" "}
      </span>
    </footer>
  );
};

export default Footer;
