import React, { useState, useCallback, useEffect } from "react";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import Pagination from "rsuite/esm/Pagination/Pagination";
import NoContentCard from "../../../atoms/NoContentCard";
import NoContentIcon from "../../../icons/NocontentIcon";
import { useCookies } from "react-cookie";
import { getData } from "../../../../services";
import moment from "moment";
import Upload from "../../../icons/Upload";
import Download from "../../../icons/Download";
import { handleClickDownload } from "../../../../services/downloadService";

const ReportsHistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [tableData, setTableData] = useState([]);
  const [cookies] = useCookies(["b_t", "bid", "br"]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const getHistory = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "BusinessReport/getAllReportHistory",
        params: {
          page: currentPage,
          page_limit: itemsPerPage,
          business_id: cookies.bid,
        },
        token: cookies.b_t,
      });

      if (res) {
        const dataWithSNo = res.data.map((item, index) => ({
          ...item,
          sno: (currentPage - 1) * itemsPerPage + index + 1,
        }));
        setTableData({ ...res, data: dataWithSNo });
      }
    } catch (err) {}
  }, [currentPage, itemsPerPage, cookies.b_t]);

  const columns = [
    {
        field: "sno",
        headerName: "S.No.",
        flex: 0.5,
        sortable: false, // Serial numbers are typically not sortable
        editable: false,
      },
      {
        field : 'exported_by',
        headerName : 'Exported By',
        flex : 1, 
        sortable: true,
      },
      {
        field : 'created_date',
        headerName : 'Exported On',
        flex : 1, 
        sortable: true,
          renderCell: (params) => {
                return (
                  <>
                    <span> {moment(params.row?.created_date).format("DD/MM/YYYY hh:mm a")}</span>
                  </>
                );
              },
      },
      {
        headerName: "Action",
        flex :0.5,
        renderCell: (params) => {
          return (
            <div className="table-icons-container">
              <div onClick={() => { handleClickDownload(params?.row?.report_url, 'Activity_Report') }}>
                <Download />
              </div>
            </div>
          );
        },
      },
  ];
  useEffect(() => {
    getHistory();
  }, [getHistory]);

  const HistoryCustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        <NoContentCard
          title="No Reports Exported yet"
          type="Company"
          icon={<NoContentIcon />}
          small={true}
        />
      </>
    );
  });
  return (
    <>
      <div className="mt-3">
        <CustomDatagrid
          columns={columns}
          rows={tableData?.data || []}
          CustomNoRowsOverlay={HistoryCustomNoRowsOverlay}
          getRowId={(row) => row.id}
          height={() => 70}
        />
      </div>

      {tableData?.total_record > 25 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={tableData?.total_record}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};

export default ReportsHistory;
