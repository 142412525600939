import { InputAdornment, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "./datepicker.scss";
import React ,  { useState } from "react";

const datePickerStyle = {
  height: "52px", // Set your desired height
  backgroundColor: "lightblue", // Set your desired background color
  border: "2px solid darkblue", // Set your desired border
};

export function MonthYearSelector({
  onChange,
  placeholder = "Select",
  selectedDate,
  disableFuture = false,
  disablePast = false,
  minDateData,
  maxDateData,
  handleBlur,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (date) => {
    if (onChange && date) {
      onChange(date.format("YYYY-MM")); // Format the selected date
      setIsOpen(false); // Close the date picker after selection
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className="calender-input-component"
        tabIndex={0}
        onClick={() => !isOpen && setIsOpen(true)} // Only open if not already open
      >
        <DatePicker
          disableFuture={disableFuture}
          disablePast={disablePast}
          openTo="month"
          views={["year", "month"]}
          style={datePickerStyle}
          onClose={handleClose}
          open={isOpen}
          minDate={minDateData ? dayjs(minDateData) : undefined}
          maxDate={maxDateData ? dayjs(maxDateData) : undefined}
          onChange={handleDateChange}
          value={selectedDate ? dayjs(selectedDate) : null}
          slotProps={{
            textField: { 
              placeholder: `${placeholder}`, 
              onBlur: handleBlur,
              readOnly: true 
            }
          }}
          className="w-100"
        />
      </div>
    </LocalizationProvider>
  );
}

export function DateSelector({
  onChange,
  placeholder = "Select Date",
  disableFuture = false,
  disablePast = false,
  disabled = false,
  selectedDate,
  minDateData,
  maxDateData,
  handleBlur,
  isError,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleDateChange = (date) => {
    if (onChange && date) {
      const isoDate = date.format("YYYY-MM-DDTHH:mm:ss");
      onChange(isoDate + "Z");
      setIsOpen(false); // Close the date picker after selection
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={`calender-input-component ${isError ? "error-container" : ""}`}
        tabIndex={0}
        onClick={() => !isOpen && !disabled && setIsOpen(true)} // Only open if not already open and not disabled
      >
        <DatePicker
          disableFuture={disableFuture}
          disablePast={disablePast}
          style={{ height: "52px", backgroundColor: "lightblue", border: "2px solid darkblue" }}
          slotProps={{
            textField: { 
              placeholder: `${placeholder}`, 
              onBlur: handleBlur,
              readOnly: true 
            }
          }}
          onChange={handleDateChange}
          format="DD/MM/YYYY"
          disabled={disabled}
          value={selectedDate ? dayjs(selectedDate) : null}
          minDate={minDateData ? dayjs(minDateData) : undefined}
          maxDate={maxDateData ? dayjs(maxDateData) : undefined}
          open={isOpen}
          onClose={handleClose}
          className="w-100"
        />
      </div>
    </LocalizationProvider>
  );
}