import React, { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import './style.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

const CustomTimePicker = ({
  onChange,
  placeholder = "Select",
  selectedTime,
  isDisabled = false,
  handleBlur,
  isError,
  minTime,
  maxTime,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tempTime, setTempTime] = useState(null);

  const handleTimeChange = (time) => {
    setTempTime(time); // Store the selected time temporarily
  };

  const handleAccept = (time) => {
    if (onChange && tempTime) {
      // Convert local time back to UTC
      const utcTime = tempTime.utc().format("HH:mm:ss");
      onChange(utcTime);
      setIsOpen(false); // Close only on OK button click
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setTempTime(null); // Reset temp time when closing without accepting
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={`time-input-component ${isError ? "error-container" : ""}`}
        tabIndex={0}
        onClick={() => !isOpen && !isDisabled && setIsOpen(true)}
      >
        <TimePicker
          views={["hours", "minutes"]}
          timeSteps={{ hours: 1, minutes: 1, seconds: 1 }}
          slotProps={{
            textField: { 
              placeholder: `${placeholder}`, 
              onBlur: handleBlur,
              readOnly: true 
            }
          }}
          className="time-picker"
          value={selectedTime ? dayjs.utc(selectedTime, "HH:mm:ss").local() : null}
          onChange={handleTimeChange}
          onAccept={handleAccept}
          disabled={isDisabled}
          minTime={minTime ? dayjs.utc(minTime, "HH:mm:ss").local() : null}
          maxTime={maxTime ? dayjs.utc(maxTime, "HH:mm:ss").local() : null}
          closeOnSelect={false}
          open={isOpen}
          onClose={handleClose}
        />
      </div>
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
