import React from "react";
import Select from "react-select";
import { countryCodeData } from "../../../helpers/countryCodes";
import {
    customDropdownStyles,
  upperDropdownStyles,
  dashboardStyles,
  errorStyles,
  filterStyles,
} from "../Dropdown/dropdown";

const CountryCodeDropdown = ({
  id,
  selectedValue,
  isClearable,
  onChange,
  placeholder = "Select country code",
  isDisabled = false,
  menuPlacement = "bottom",
  isError = false,
  handleBlur = () => {},
  noOptionsMessage = () => "No options",
}) => {
  // Handle selection change
  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      onChange(selectedOption.value);
    }
  };

  // Format the selected value to display only the country code
  const formatSelectedValue = () => {
    if (!selectedValue) return null;
    const selectedOption = countryCodeData.find((option) => option.value === selectedValue);
    return selectedOption 
      ? { ...selectedOption, label: selectedOption.value } 
      : null;
  };

  const styles = isError
      ? { ...customDropdownStyles, ...errorStyles }
      : customDropdownStyles;
  
    const filterStyle = { ...customDropdownStyles, ...filterStyles };
  
    const dashboardStyle = { ...dashboardStyles };



  return (
    <Select
      id={id}
      value={formatSelectedValue()}
      onChange={handleSelectChange}
      placeholder={placeholder}
      options={countryCodeData}
      isDisabled={isDisabled}
      isClearable={false} // Disable clear functionality
      menuPlacement={menuPlacement}
      styles={
        filterStyle
      }

      onBlur={handleBlur}
      noOptionsMessage={noOptionsMessage}
      isSearchable={true} // Optional: disable search functionality
      closeMenuOnSelect={true} // Ensure menu closes on select
      is
    />
  );
};

export default CountryCodeDropdown;