import React, { useCallback, useState, useMemo, useEffect } from "react";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import NoContentCard from "../../../../atoms/NoContentCard";
import NoContentIcon from "../../../../icons/NocontentIcon";
import Pagination from "../../../../molecules/Pagination";
import ImageAndInitialViewer from "../../../../molecules/ImageAndInitialViewer";
import View from "../../../../icons/View";
import Modal from "../../../../organisms/modals/Modal";
import moment from "moment";
import ImageModal from "../../../../organisms/modals/ImageModal";
import VisitorLogsDetails from "../Views/Current";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import { convertUtcToLocalTime } from "../../../../../helpers/convertUtcToLocalTime";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import CustomMenu from "../../../../atoms/CustomMenu";

const MeetInformation = ({
  type,
  data,
  currentPage,
  itemsPerPage,
  setCurrentPage,
  setItemsPerPage,
}) => {
  const screenWidth = useScreenWidth();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  const [tableData, setTableData] = useState({});
  const [modalState, setModalState] = useState({
    modalId: "",
    name: "",
    isViewModalOpen: false,
    data: null,
  });
  const [image, setImage] = useState({
    isActive: null,
    image: null,
  });

  console.log("anchorEl :", anchorEl, selectedRow);

  useEffect(() => {
    const element = document.querySelector(".MuiDataGrid-virtualScroller");
    if (element) {
      element.scrollLeft = 0;
    }
  }, [type]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  const handleView = (data) => {
    setModalState((prevState) => ({
      ...prevState,
      isViewModalOpen: true,
      modalId: data.id,
      data: data,
    }));
    setSelectedRow(null);
  };
  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    console.log("row :", row);

    setSelectedRow(row);
  };

  console.log("selected Row:", selectedRow);

  const handleClose = () => {
    setSelectedRow(null);
    setAnchorEl(null);
  };
  const menu = [
    {
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        setAnchorEl(null);
        handleView(selectedRow);
      },
    },
  ];

  const columnConfigurations = {
    current: [
      {
        field: "check_in_photo",
        headerName: "Photo",
        ...(screenWidth < 1230 ? { width: 90 } : { flex: 0.5 }),
        renderCell: (params) => {
          return (
            <>
              <div
                onClick={() =>
                  setImage({ isActive: true, image: params.row.check_in_photo })
                }
                className="cursor-pointer"
              >
                <ImageAndInitialViewer image={params.row.check_in_photo} />
              </div>
            </>
          );
        },
      },
      {
        field: "name",
        headerName: "Invitee Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <>

              <span className="ms-2 text-ecp" title={params.row.name}>
                {params.row.name}
              </span>
        
              <span className="ms-1">
                {params.row?.addition_visitor?.length > 0
                  ? `+${params.row.addition_visitor?.length}`
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        field: "contact_deatils",
        headerName: "Contact Details",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <div className="d-flex flex-column">
              {params.row.contact_no && <span>{params.row.contact_no}</span>}
              {params.row.email && params.row.email !== "null" && (
                <span>{params.row.email}</span>
              )}
              <span></span>
            </div>
          );
        },
      },
      {
        field: "employee_name",
        headerName: "Host Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      },
      {
        field: "check_in",
        headerName: "Check In Time",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <div>
              {params?.row?.check_in_time &&
                moment(params.row?.check_in_time).format("DD/MM/YYYY hh:mm a")}
            </div>
          );
        },
      },
      {
        field: "actions",
        headerName: "Action",
        ...(screenWidth < 1230 ? { width: 100 } : { flex: 0.5 }),
        renderCell: (params) => {
          return (
            <div className="table-icons-container">
              {/* <div onClick={(event) => handleStatusMenu(event, params.row)}>
                <HorizontalMenu />
              </div>

              {console.log("Done Selected :", selectedRow, params.row)}
              <CustomMenu
                anchorEl={anchorEl}
                open={selectedRow === params.row}
                onClose={handleClose}
                items={menu}
              /> */}
              <div onClick={() => handleView(params.row)}>
                <View height="20" width="20" />
              </div>
            </div>
          );
        },
      },
      // Add other column configurations for current visitors
    ],
    upcoming: [
      {
        field: "name",
        headerName: "Invitee Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1.5 }),
        renderCell: (params) => {
          return (
            <>
              <ImageAndInitialViewer name={params.row.name} />
              <div onClick={() => handleView(params.row)}>
              <span className="ms-2 text-ecp" title={params.row.name}>
                {params.row.name}
              </span>
              </div>
              <span className="ms-1">
                {params.row?.addition_visitor?.length > 0
                  ? `+${params.row.addition_visitor?.length}`
                  : ""}
              </span>
            </>
          );
        },
      },
      {
        field: "contact_deatils",
        headerName: "Contact Details",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <div className="d-flex flex-column">
              {params.row.contact_no && <span>{params.row.contact_no}</span>}
              {params.row.email && params.row.email !== "null" && (
                <span>{params.row.email}</span>
              )}
              <span></span>
            </div>
          );
        },
      },
      {
        field: "employee_name",
        headerName: "Host Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      },
      {
        field: "venue_name",
        headerName: "Venue Name",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      },
      {
        field: "appointmentDate",
        headerName: "Time For Meeting",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
        renderCell: (params) => {
          return (
            <>
              <span>
                {" "}
                {moment(params.row?.date).format("DD/MM/YYYY")}{" "}
                {moment
                  .utc(params.row.start_time, "HH:mm:ss")
                  .local()
                  .format("hh:mm A")}
              </span>
            </>
          );
        },
      },
      {
        field: "status",
        headerName: "Status",
        ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
      },
      {
        field: "actions",
        headerName: "Action",
        ...(screenWidth < 1230 ? { width: 100 } : { flex: 0.5 }),
        renderCell: (params) => {
          return (
             <div className="table-icons-container">
              <div onClick={() => handleView(params.row)}>
                <View height="20" width="20" /> 
              </div>
             </div>
            
          );
        },
      },
      // Add other column configurations for upcoming visitors
    ],
    // history: [
    //   {
    //     field: "check_in_photo",
    //     headerName: "Photo",
    //     ...(screenWidth < 1230 ? { width: 90 } : { flex: 0.5 }),
    //     renderCell: (params) => {
    //       return (
    //         <div
    //           onClick={() =>
    //             setImage({ isActive: true, image: params.row.check_in_photo })
    //           }
    //           className="cursor-pointer"
    //         >
    //           <ImageAndInitialViewer image={params.row.check_in_photo} />
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     field: "name",
    //     headerName: "Visitor Name",
    //     ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
    //     renderCell: (params) => {
    //       return (
    //         <>
    //           <span className="ms-2 text-ecp" title={params.row.name}>
    //             {params.row.name}
    //           </span>
    //           <span className="ms-1">
    //             {params.row?.addition_visitor?.length > 0
    //               ? `+${params.row.addition_visitor?.length}`
    //               : ""}
    //           </span>
    //         </>
    //       );
    //     },
    //   },
    //   {
    //     field: "contact_deatils",
    //     headerName: "Contact Details",
    //     ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
    //     renderCell: (params) => {
    //       return (
    //         <div className="d-flex flex-column">
    //           {params.row.contact_no && (
    //             <span title={params.row.contact_no}>
    //               {params.row.contact_no}
    //             </span>
    //           )}
    //           {params.row.email && params.row.email !== "null" && (
    //             <span title={params.row.email}>{params.row.email}</span>
    //           )}
    //           <span></span>
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     field: "company",
    //     headerName: "Company",
    //     ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
    //   },
    //   {
    //     field: "venue_name",
    //     headerName: "Venue Name",
    //     ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
    //   },
    //   {
    //     field: "employee_name",
    //     headerName: "Host Name",
    //     ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
    //   },
    //   {
    //     field: "check_in",
    //     headerName: "Check In Time",
    //     ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
    //     renderCell: (params) => {
    //       return (
    //         <div>
    //           {params?.row?.check_in_time &&
    //             moment(params.row?.check_in_time).format("DD/MM/YYYY hh:mm a")}
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     field: "check_out",
    //     headerName: "Check Out Time",
    //     ...(screenWidth < 1230 ? { width: 200 } : { flex: 1 }),
    //     renderCell: (params) => {
    //       return (
    //         <div>
    //           {params?.row?.check_out_time &&
    //             moment(params.row?.check_out_time).format("DD/MM/YYYY hh:mm a")}
    //         </div>
    //       );
    //     },
    //   },
    //   {
    //     field: "actions",
    //     headerName: "Action",
    //     ...(screenWidth < 1230 ? { width: 100 } : { flex: 0.5 }),
    //     renderCell: (params) => {
    //       return (
    //         <div className="table-icons-container">
    //           <div onClick={(event) => handleStatusMenu(event, params.row)}>
    //             <HorizontalMenu />
    //           </div>

    //           <CustomMenu
    //             anchorEl={anchorEl}
    //             open={Boolean(anchorEl) && selectedRow === params.row}
    //             onClose={handleClose}
    //             items={menu}
    //           />
    //         </div>
    //       );
    //     },
    //   },
    //   // Add other column configurations for visitor history
    // ],
  };

  const columns = useMemo(() => {
    return columnConfigurations[type] || [];
  }, [type]);

  const CustomNoRowsOverlay = React.memo(() => {
    return (
      <>
        <NoContentCard
          title="No Record Found"
          type="Company"
          icon={<NoContentIcon />}
          small={true}
        />
      </>
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  function generateRandomToken(length = 32) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      token += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return token;
  }

  return (
    <>
      <CustomDatagrid
        getRowId={(row) => generateRandomToken()}
        rows={tableData?.data || []}
        columns={columnConfigurations?.[type]}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        tHeight="25em"
        height={() => 70}
        hoverColor="fff"
        onRowClick={(params) => handleView(params.row)}
      />

      {tableData?.total_record > 25 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={tableData?.total_record}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}

      {modalState.isViewModalOpen && (
        <Modal
          name={`Invitee Details`}
          title={`Invitee Details`}
          id={modalState.modalId}
          isActive={modalState.isViewModalOpen}
          onClose={() =>
            setModalState((prevState) => ({
              ...prevState,
              isViewModalOpen: false,
            }))
          }
          width="640px"
        >
          {type === "current" ? (
            <VisitorLogsDetails data={modalState.data} />
          ) : type === "upcoming" ? (
            <VisitorLogsDetails data={modalState.data} />
          ) : (
            <VisitorLogsDetails data={modalState.data} />
          )}
        </Modal>
      )}

      {image?.isActive && (
        <ImageModal
          isActive={image?.isActive}
          setIsActive={setImage}
          image={image?.image}
        />
      )}
    </>
  );
};

export default MeetInformation;

