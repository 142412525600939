import ReactECharts from 'echarts-for-react';
import moment from 'moment';

const VisitorChart = ({ data }) => {
  // Extract formatted dates
  const dates = data?.map(item => moment(item?.date).format("DD/MM/YYYY"));

  // Calculate total visitors for the y-axis
  const totalVisitors = data?.map(item => item.current_visitors + item.checked_out);

  // Extract separate values for tooltip display
  const currentVisitors = data?.map(item => item.current_visitors);
  const checkedOut = data?.map(item => item.checked_out);

  const options = {
    grid: { top: 8, right: 8, bottom: 24, left: 36 },
    xAxis: {
      type: 'category',
      data: dates,
    },
    yAxis: {
      type: 'value',
      minInterval: 1,
    },
    series: [
      {
        data: totalVisitors,
        type: 'line',
        smooth: true,
        itemStyle: {
          color: '#6B66DA', // Color of the data points (circles)
        },
        lineStyle: {
          color: '#6B66DA', // Color of the line
        },
      },
    ],
    tooltip: {
      trigger: 'axis',
      backgroundColor: '#fff', // White background
      borderColor: '#6B66DA', // Border color
      borderWidth: 1,
      textStyle: {
        color: '#333', // Text color
        fontSize: 12,
      },
      padding: 5,
      formatter: (params) => {
        const index = params[0].dataIndex;
        return `
          <div style="padding: 8px; border-radius: 6px; font-family: Arial, sans-serif;">
            <div style="font-size: 14px; font-weight: bold; margin-bottom: 5px;">
              ${dates[index]}
            </div>
            <div style="font-size: 12px; line-height: 1.6;">              
              <span style="color: #333; font-weight: 600;">Current Visitors:</span> 
              <span >${currentVisitors[index]}</span> <br/>
              
              <span style="color: #333; font-weight: 600;">Checked Out:</span> 
              <span >${checkedOut[index]}</span>
            </div>
          </div>
        `;
      },
    },
    
  };

  return <ReactECharts option={options} />;
};

export default VisitorChart;
